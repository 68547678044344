import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import HCaptcha from '@hcaptcha/react-hcaptcha'
import { Alert, Button, Header, Input } from '@pluggyai/ui'
import {
  addBreadcrumb,
  captureException,
  captureMessage,
  Severity,
} from '@sentry/react'
import { isAxiosError } from 'axios'
import {
  ConnectorCredential,
  getErrorResponse,
  HttpStatusCode,
  isPluggyServerError,
  isValidationErrorResponse,
  Parameters,
} from 'pluggy-js'
import { Form, Image } from 'semantic-ui-react'

import { TrackEventName } from '../../../../modules/analytics/events'
import { track } from '../../../../modules/analytics/utils'
import {
  hasConnectorAccountFlowOptions,
  hasCooperativeSelectionFlow,
  hasDeviceRegistrationNotificationAlert,
  hasEmailMfaFlow,
  isGenialConnector,
  isQRConnector,
  isOauth2Connector,
  isPersonalSafraConnector,
  isSandboxConnector,
  isWiseConnector,
  isINSSConnector,
  isPJOpenFinance,
} from '../../../../modules/connector/utils'
import {
  getItemParameterOauthUrl,
  itemUpdateRequiresAllCredentials,
} from '../../../../modules/item/utils'
import { getAppProps, onEventCallback } from '../../../../utils/appWrapper'
import { usePrevious } from '../../../../utils/hooks/usePrevious'
import { BASE_INPUT_MODE } from '../../../../utils/input-mask/inputMode'
import { Title } from '../../Title'
import { trackLoginStepSuccess } from '../../utils'
import { ConnectorHeader } from '../ConnectorHeader'
import { AccountFlowOption, AccountFlowSelection } from './AccountFlowSelection'
import { MfaStage, Props } from './ConnectForm.types'
import { ConnectionSecurePopup } from './ConnectionSecurePopup/'
import { ConnectWalletInput } from './ConnectWalletInput'
import { CooperativeFlowSelection } from './CooperativeFlowSelection'
import { GenialMfaInstructions } from './GenialMfaInstructions'
import { useConnectFormHeaderI18nKey, useOpenFinanceCredentials } from './hooks'
import { MaskedInput } from './MaskedInput'
import {
  MFA_ALMOST_TIMEOUT_MS,
  MfaRemainingTimeMessage,
} from './Mfa2StepConnectForm/MfaRemainingTimeMessage'
import { Mfa2StepInstructions } from './Mfa2StepInstructions/'
import { MfaSelectRequestExpired } from './MfaRequestExpired'
import { OauthConnectForm } from './OauthConnectForm'
import { OauthTimeoutError } from './OauthTimeoutError'
import { OpenFinanceFAQButton } from './OpenFinanceFAQButton'
import { OpenFinancePermissions } from './OpenFinancePermissions'
import { QRActionInstructions } from './QRAction/QRActionInstructions'
import { QRActionTimeout } from './QRAction/QRActionTimeout'
import { SandboxCredentialSuggestion } from './SandboxCredentialSuggestion'
import { UserActionInstructions } from './UserActionInstructions'
import {
  extractFileExtensions,
  FieldErrors,
  FieldValues,
  FORM_NAME,
  getApiErrorResponseMessage,
  getConnectButtonText,
  getHelpLinksByConnector,
  getMfaInitialRemainingTime,
  isFileUploadInput,
  isUserAccountError,
  mapExecutionErrorI18nKey,
  validateField,
  validateFields,
} from './utils'
import { WiseInstructions } from './WiseInstructions'

import './ConnectForm.css'

const CREATE_ITEM_LIMIT = 100

export type ConnectFormFieldProps = {
  type: 'password' | 'text' | 'select' | 'ethaddress' | 'hcaptcha'
  value: string
  // list of possible options, for 'select' type field
  options?: { value: string; label: string }[]
  name: string
  label: string
  isMfa: boolean
  imageData?: string
  isOptional: boolean
  validation?: string
  validationMessage?: string
  instructions?: string
  assistiveText?: string
  placeholder?: string
}

type ConnectFormProps = {
  onSubmit: () => Promise<void> | void
  className: string
  fields: ConnectFormFieldProps[]
}

const ConnectForm = ({
  selectedConnector,
  item,
  error,
  credentials: submittedCredentials,
  isUpdate,
  isItemFetching,
  isItemCreating,
  isItemUpdating,
  isItemMfaSubmitting,
  isPollingItemCreate,
  isPollingItemUpdate,
  isPollingItemMfaSubmit,
  isItemsCreateQuotaExceeded: isItemsCreateQuotaExceededProp,
  withImage,
  onCreateItem,
  onSendMFA,
  onClearConnectItem,
  onClearConnectItemError,
  onUpdateItem,
  onCredentialsSubmit,
  onRetry,
  onMfaStageChange,
  onFormLoadingStateChange,
  itemsCreateLimit,
  serverDateDeltaInMs,
  customFormButtonText,
  onGoToFAQPage,
  openFinanceParameters,
}: Props) => {
  const isPollingItemAnyAction =
    isPollingItemCreate || isPollingItemUpdate || isPollingItemMfaSubmit

  const isAnySubmitLoading =
    isItemFetching ||
    isItemCreating ||
    isItemUpdating ||
    isItemMfaSubmitting ||
    isPollingItemAnyAction ||
    false

  const { t } = useTranslation()

  const [inputValues, setInputValues] = useState<FieldValues>(
    submittedCredentials || {},
  )
  const [formErrors, setFormErrors] = useState<FieldErrors>({})

  const [isCreateLoading, setCreateLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<
    JSX.Element | string | undefined
  >(undefined)

  const isSandbox = isSandboxConnector(selectedConnector)

  const isWaitingMfaInput = item?.status === 'WAITING_USER_INPUT'

  const { oauthUrl } = selectedConnector
  const isOauthForm = Boolean(oauthUrl) || isOauth2Connector(selectedConnector)

  const { moveSecurityData } = getAppProps()

  const itemMfa1StepCredential = item?.connector.credentials.find(
    (c: ConnectorCredential) => c.mfa,
  )

  const itemMfa2StepParameter = item?.parameter
  const currentMfaParameter = itemMfa2StepParameter
  const previousMfaParameter = usePrevious(currentMfaParameter)

  const isOauthMfaParameter =
    (currentMfaParameter?.type as string | undefined) === 'oauth'

  useEffect(() => {
    // stop isCreateLoading when MFA parameter has been requested
    if (!isCreateLoading || !item) {
      return
    }
    if (currentMfaParameter && currentMfaParameter !== previousMfaParameter) {
      // we've been requested a new parameter
      setCreateLoading(false)
    }
  }, [item, currentMfaParameter, isCreateLoading, previousMfaParameter])

  // Remaining time for MFA 2-step parameter
  const [remainingTimeMs, setRemainingTimeMs] = useState<number | undefined>()

  const isWaitingMfaResponse = isItemMfaSubmitting || isPollingItemMfaSubmit
  const isWaitingMfaRequest =
    isItemUpdating || isPollingItemUpdate || isItemFetching

  const [mfaFormCredential, setMfaFormCredential] = useState<
    ConnectorCredential & { stage: MfaStage }
  >()

  const previousMfaStage = usePrevious(mfaFormCredential?.stage)

  useOpenFinanceCredentials(
    setInputValues,
    selectedConnector,
    openFinanceParameters,
  )
  // notify to connect the current stage of the form to allow header change the title correctly
  useEffect(() => {
    if (mfaFormCredential?.stage === previousMfaStage) {
      // same stage -> just return
      return
    }

    onMfaStageChange(mfaFormCredential?.stage)
  }, [mfaFormCredential, onMfaStageChange, previousMfaStage])

  useEffect(() => {
    onFormLoadingStateChange(isCreateLoading)
  }, [isCreateLoading, onFormLoadingStateChange])

  useEffect(() => {
    // if is MFA 2-step, update the timer
    if (remainingTimeMs === undefined || remainingTimeMs <= 0) {
      return
    }

    let tick: NodeJS.Timeout | undefined = undefined
    // if submitted, or waiting new MFA, stop timer too
    if (
      isCreateLoading ||
      isAnySubmitLoading ||
      isWaitingMfaRequest ||
      isWaitingMfaResponse
    ) {
      if (typeof tick !== 'undefined') {
        clearTimeout(tick)
      }
      return
    }

    tick = setTimeout(
      () =>
        setRemainingTimeMs((time) =>
          time !== undefined ? Math.floor(Math.max(time - 1000, 0)) : undefined,
        ),
      1000,
    )

    // ignore this error, this is a React cleanup function special case
    // eslint-disable-next-line consistent-return
    return () => {
      if (typeof tick !== 'undefined') {
        clearTimeout(tick)
      }
    }
  }, [
    remainingTimeMs,
    isCreateLoading,
    isAnySubmitLoading,
    isWaitingMfaRequest,
    isWaitingMfaResponse,
  ])

  const clearAndSubmitForm = useCallback(
    (formData: Parameters) => {
      setErrorMessage(undefined)
      setCreateLoading(true)

      if (!item) {
        if (isUpdate) {
          // is Update, but item is not present
          throw new Error("Can't update, item has not been loaded yet!")
        }

        if (moveSecurityData) {
          // append move security request payload on item create request formData
          // this works as any other connector credential, but it's not documented publicly
          formData.securityPortabilityData = moveSecurityData
        }

        // create new item request
        onCredentialsSubmit(formData)
        onCreateItem(selectedConnector, formData)
        return
      }

      // item is present, check if we are sending an MFA param, or just updating existing item
      const is2StepParameterSubmit = mfaFormCredential?.stage === '2-step'

      if (is2StepParameterSubmit) {
        // reset input values
        setInputValues({})
        // send the MFA parameter request
        onSendMFA(item.id, formData)
        return
      }

      // update the already existing item
      onUpdateItem(item.id, formData)
      return
    },
    [
      item,
      mfaFormCredential?.stage,
      onUpdateItem,
      isUpdate,
      moveSecurityData,
      onCredentialsSubmit,
      onCreateItem,
      selectedConnector,
      onSendMFA,
    ],
  )

  const resetFormError = useCallback(
    (fieldName: string) => {
      if (!formErrors[fieldName]) {
        return
      }

      setFormErrors((previousFormErrors) => {
        // disabling this rule because we want to remove the key from the object
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [fieldName]: _removedKey, ...restFormErrors } =
          previousFormErrors

        return restFormErrors
      })
      if (item) {
        const { id } = item
        // reset stored item error state
        onClearConnectItemError(id)
      }
    },
    [formErrors, item, onClearConnectItemError],
  )

  const handleSubmit = useCallback(() => {
    const parameters: Parameters = {}
    for (const [fieldName, value] of Object.entries(inputValues)) {
      parameters[fieldName] = String(value)
    }
    const hasErrors = Object.keys(formErrors).length > 0
    if (hasErrors) {
      return
    }
    clearAndSubmitForm(parameters)
    onEventCallback({ event: 'SUBMITTED_LOGIN' })
    const { name: connectorName, id: connectorId } = selectedConnector
    track(TrackEventName.FORM_SUBMITTED, {
      connectorName,
      connectorId,
      location: 'connectForm',
      form: FORM_NAME.LOGIN,
    })
  }, [formErrors, clearAndSubmitForm, selectedConnector, inputValues])

  // track form errors & input values refs to clear them when MFA parameter is set
  // we use refs so we don't have to have them as effect dependencies
  const formErrorsRef = useRef<FieldErrors>({})
  useEffect(() => {
    formErrorsRef.current = formErrors
  }, [formErrors])
  const inputValuesRef = useRef<FieldValues>({})
  useEffect(() => {
    inputValuesRef.current = inputValues
  }, [inputValues])

  useEffect(() => {
    if (mfaFormCredential?.stage === previousMfaStage) {
      // we are on the same stage, we don't need to clear errors or inputs -> just return
      return
    }
    // when mfa form credential is set, we clear the form errors & input values.
    const hasFormErrors = Object.keys(formErrorsRef.current).length > 0
    const hasInputValues = Object.keys(inputValuesRef.current).length > 0
    if (hasFormErrors) {
      // clear form errors
      setFormErrors({})
    }
    if (hasInputValues) {
      // clear form input values
      setInputValues({})
    }
  }, [mfaFormCredential, previousMfaStage])

  const handleSubmitMfa = useCallback(() => {
    // submit of MFA parameter, either for 2-step credential - or 1-step item update.
    if (!mfaFormCredential) {
      console.error('Invalid state: mfaFormCredential is not defined', {
        item,
      })
      return
    }

    const hasErrors = Object.keys(formErrors).length > 0
    if (hasErrors) {
      return
    }

    const { name } = mfaFormCredential
    const extraParamValue = inputValues[name]

    if (!extraParamValue) {
      return
    }

    const parameters: Parameters = {
      [name]: extraParamValue,
    }

    onEventCallback({ event: 'SUBMITTED_MFA' })
    const { name: connectorName, id: connectorId } = selectedConnector
    track(TrackEventName.FORM_SUBMITTED, {
      location: 'connectForm',
      mfaStage: currentMfaParameter,
      connectorName,
      connectorId,
      form: FORM_NAME.LOGIN_MFA_2_STEP,
    })
    clearAndSubmitForm(parameters)
  }, [
    mfaFormCredential,
    formErrors,
    inputValues,
    selectedConnector,
    currentMfaParameter,
    clearAndSubmitForm,
    item,
  ])

  const handleInputChange = useCallback(
    ({ name: fieldName = '', value }: { name?: string; value: string }) => {
      const newInputValues: FieldValues = {
        ...inputValues,
        [fieldName]: value,
      }

      setInputValues(newInputValues)
      // reset errors
      resetFormError(fieldName)
      setErrorMessage(undefined)
    },
    [inputValues, setInputValues, resetFormError],
  )

  const handleRadioSelectInputChange = useCallback(
    ({ name: fieldName = '', value }) => {
      const newInputValues: Record<string, string> = {
        ...inputValues,
        [fieldName]: value,
      }

      setInputValues(newInputValues)
      // reset errors
      resetFormError(fieldName)
      setErrorMessage(undefined)
    },
    [inputValues, resetFormError],
  )

  const resetPasswordLinkI18nKey = 'connectorForm.reset-password'

  const resetPasswordLinkText = t(resetPasswordLinkI18nKey)

  const trackResetPasswordLinkClick = useCallback(() => {
    track(TrackEventName.LINK_CLICKED, {
      connector: selectedConnector,
      i18nKey: resetPasswordLinkI18nKey,
      text: resetPasswordLinkText,
      linkTo: selectedConnector.institutionUrl,
      location: 'connectForm',
    })
  }, [resetPasswordLinkText, selectedConnector])

  // set the MFA 2-step credential to the state, so we keep showing it after submitted.
  useEffect(() => {
    if (!itemMfa2StepParameter || !isWaitingMfaInput) {
      return
    }

    // add MFA parameter to state
    setMfaFormCredential({ ...itemMfa2StepParameter, stage: '2-step' })

    // login before MFA 2-step was completed success -> notify it.
    // TODO don't fire LOGIN_SUCCESS here if is Oauth MFA 2-step! we didn't complete login yet
    // see: https://pluggy.atlassian.net/browse/TRIN-185
    // ALSO ENSURE WE DON'T FIRE IT MANY TIMES WHEN CLOSING/OPEN THE WINDOW OR OTHER THINGS
    onEventCallback({
      event: 'LOGIN_SUCCESS',
      item,
    })

    // we are in the 2-step MFA stage, so we already finish the 1-step flow, track it
    trackLoginStepSuccess(item, isUpdate)
  }, [itemMfa2StepParameter, isWaitingMfaInput, isUpdate, item])

  // set MFA 1-step credential as MFA form state when in Update mode
  useEffect(() => {
    const hasMfa2StepParameterRequest =
      itemMfa2StepParameter && isWaitingMfaInput
    const requiresAllCredentials =
      item && itemUpdateRequiresAllCredentials(item)

    if (
      // not in update mode
      !isUpdate ||
      // had an error that requires all credentials
      requiresAllCredentials ||
      // no MFA 1-step credential available
      !itemMfa1StepCredential ||
      // MFA credential has already been set
      mfaFormCredential ||
      // the item is WAITING_MFA_INPUT state, which is 2-step
      hasMfa2StepParameterRequest
    ) {
      // don't set the MFA 1-step form credential state
      return
    }
    setMfaFormCredential({ ...itemMfa1StepCredential, stage: '1-step' })
  }, [
    item,
    isUpdate,
    itemMfa1StepCredential,
    formErrors,
    mfaFormCredential,
    setMfaFormCredential,
    itemMfa2StepParameter,
    isWaitingMfaInput,
  ])

  // check if we have to remove the MFA parameter from state
  useEffect(() => {
    if (!item || !mfaFormCredential) {
      // no item, or no MFA parameter -> nothing to do
      return
    }

    const requiresAllCredentials = itemUpdateRequiresAllCredentials(item)

    if (requiresAllCredentials) {
      setMfaFormCredential(undefined)
      setRemainingTimeMs(undefined)
    }
  }, [item, mfaFormCredential])

  const isMfaRequestExpired =
    mfaFormCredential &&
    remainingTimeMs !== undefined &&
    Math.floor(remainingTimeMs / 1000) <= 0

  const previousWasPollingItemAnyAction = usePrevious(isPollingItemAnyAction)
  const pollingStopped =
    previousWasPollingItemAnyAction && !isPollingItemAnyAction

  const isQRLoginFlowShowingQR =
    item &&
    item.executionStatus === 'WAITING_USER_ACTION' &&
    item.userAction?.attributes?.name === 'qr' &&
    Boolean(item.userAction.attributes.data)

  const isQRConnectorFlow = isQRConnector(selectedConnector)

  const isUserInputTimeout = item?.executionStatus === 'USER_INPUT_TIMEOUT'

  const handleQRflowSubmit = useCallback(() => {
    if (isUserInputTimeout) {
      onRetry()
      return
    }
    // submit empty credentials, since we need to trigger the connector execution
    clearAndSubmitForm({})
  }, [clearAndSubmitForm, isUserInputTimeout, onRetry])

  const isActiveItemWithSameCredentialsExecutingError =
    isAxiosError(error) &&
    error.response?.status === 400 &&
    (error.response.data as { message: string } | undefined)?.message.includes(
      'There is an active item for the set of credentials',
    )

  // check for create/update item error result & update error message
  useEffect(() => {
    if (
      pollingStopped &&
      (isCreateLoading || isUpdate) &&
      item &&
      (item.status === 'LOGIN_ERROR' || item.status === 'OUTDATED')
    ) {
      const {
        connector: {
          id: connectorId,
          name: connectorName,
          type: connectorType,
        },
        error: itemError,
      } = item

      // adding code: null in case that itemError object is null
      const { code: itemErrorCode } = itemError || { code: null }

      // track errors such as invalid credentials
      track(TrackEventName.FORM_ERROR_SETTED, {
        error: item.error,
        connectorName,
        connectorId,
        connectorType,
      })

      if (!isUserAccountError(itemErrorCode)) {
        // this error is unexpected, we are navigating to
        // error page but return early to prevent rendering unexpected errors
        return
      }

      if (isQRConnectorFlow && isUserInputTimeout) {
        // special case when we are in the interQR connector flow we don't want to show an error in timeout -> just return
        setCreateLoading(false)
        return
      }

      // if item was isCreateLoading AND status+executionStatus is FINISHED with ERROR
      // we update the error message
      const TransMessageElement = (
        <Trans
          i18nKey={mapExecutionErrorI18nKey(itemErrorCode, selectedConnector)}
          components={{
            a: (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                href={selectedConnector.institutionUrl}
                className={'link'}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      )
      // set error message, except if Invalid MFA, in that case we just set it
      // in the parameter itself.
      const isInvalidMfaError =
        item.executionStatus === 'INVALID_CREDENTIALS_MFA' ||
        item.executionStatus === 'USER_INPUT_TIMEOUT' ||
        item.error?.code === 'INVALID_CREDENTIALS_MFA' ||
        item.error?.code === 'USER_INPUT_TIMEOUT'

      if (isInvalidMfaError) {
        // is MFA error -> act on it
        if (mfaFormCredential) {
          // set the error on the MFA field only
          const { label, name, stage: mfaStage } = mfaFormCredential
          if (mfaStage === '2-step' && itemMfa1StepCredential) {
            // we were in MFA 2-step, but got an error, and the item also has MFA 1-step.
            // So we need to reset the form back to the 1-step parameter.
            // Also, we set the error message in the form itself (so it can be seen)
            setMfaFormCredential({ ...itemMfa1StepCredential, stage: '1-step' })
            setInputValues({})
            setErrorMessage(TransMessageElement)
          } else {
            // we weren't in MFA 2-step, or we were but there is no MFA 1-step parameter,
            // so we just set the error in the current mfaFormCredential field
            setFormErrors((prevFormErrors) => ({
              ...prevFormErrors,
              // TODO use actual error message? or is this generic OK?
              [name]: t('connectorForm.field-error.invalid', { label }),
            }))
          }
        } else if (itemMfa1StepCredential) {
          // is invalid MFA credential error but we aren't in the MFA 2-step form,
          // so this is the full login form. Set the error in the MFA field only.
          const { name, label } = itemMfa1StepCredential
          setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            // TODO use actual error message? or is this generic OK?
            [name]: t('connectorForm.field-error.invalid', { label }),
          }))
        } else {
          // no MFA credential set, and no MFA 1-step credentail available,
          // so we set the error message on the form itself
          setErrorMessage(TransMessageElement)
        }
      } else {
        // no MFA error (something else) -> set the error message on the form itself
        setErrorMessage(TransMessageElement)
      }

      setCreateLoading(false)

      return
    }

    if (!error) {
      // no error result/response is present, no need to update errorMessage
      return
    }

    // is an error, stop loading and update error state
    if (isCreateLoading) {
      setCreateLoading(false)
    }

    if (typeof error === 'string') {
      // is an oauth result error
      // TODO improve this error info, ie. render the actual `error.message`?
      //  however, it'll need some QA reviewing, since some of them are backend errors and ugly for frontend
      captureMessage(`ConnectForm: unknown error '${error}'`, {
        level: Severity.Error,
        fingerprint: [error],
      })
      setErrorMessage(t('connectorForm.error.unknown_not_valid_credentials'))
      return
    }

    if (isActiveItemWithSameCredentialsExecutingError) {
      setErrorMessage(
        t('connectorForm.error.item_with_same_credentials_executing'),
      )
      return
    }

    // check if error is too many consecutive login failures
    const isTooManyConsecutiveLoginFailuresError =
      isAxiosError(error) &&
      error.response?.status === 400 &&
      // TODO improve this way of detecting the error when we improve pluggy-api error responses (ie. add error code)
      getApiErrorResponseMessage(error)?.includes('must wait at least')

    if (isTooManyConsecutiveLoginFailuresError) {
      setErrorMessage(
        t('connectorForm.error.too_many_consecutive_login_failures'),
      )
      return
    }

    const isMfaParamerterWasAlreadyUsedError =
      isAxiosError(error) &&
      // casting to { message: string } because the error.response?.data is of type unknown
      getApiErrorResponseMessage(error)?.includes(
        'MFA parameter has to be updated from last execution',
      )

    if (isMfaParamerterWasAlreadyUsedError) {
      const mfaCredentials = selectedConnector.credentials.filter(
        ({ mfa }) => mfa,
      )
      const [mfaCredentialName] = mfaCredentials.map(({ name }) => name)

      // when API responds with 400 and message "MFA parameter has to be updated from last execution"
      // we set the error message on the MFA field only, not as an alert because it's a credentials error.
      setFormErrors((prevFormErrors) => ({
        ...prevFormErrors,
        [mfaCredentialName]: t('connectorForm.field-error.invalid', {
          label: mfaCredentialName,
        }),
      }))

      return
    }

    const isApiAuthError = isAxiosError(error) && error.response?.status === 403

    if (isApiAuthError) {
      setErrorMessage(t('auth.expired_token'))
      return
    }

    const itemUserAlreadyExistsError =
      isAxiosError(error) &&
      error.response?.status === 400 &&
      error.response.data?.codeDescription === 'ITEM_USER_ALREADY_EXISTS'

    if (itemUserAlreadyExistsError) {
      setErrorMessage(
        <Trans i18nKey={'connectorForm.error.item-user-already-exists'} />,
      )
      return
    }

    const isApiItemCreateLimitError =
      isAxiosError(error) &&
      error.response?.status === 409 &&
      error.response.data?.codeDescription === 'ITEM_CREATION_LIMIT_EXCEEDED'

    if (isApiItemCreateLimitError) {
      setErrorMessage(
        <Trans
          i18nKey={'connectorForm.error.item-creation-limit-reached'}
          values={{ limit: itemsCreateLimit || CREATE_ITEM_LIMIT }}
        />,
      )
      return
    }

    const isUpdateLimitFrequencyError = isAxiosError(error)
      ? error.response?.status === 409 &&
        error.response.data?.codeDescription ===
          'CLIENT_IS_UPDATING_BEFORE_ALLOWED_FREQUENCY'
      : false

    if (isUpdateLimitFrequencyError) {
      setErrorMessage(t('connectorForm.error.item-update-limit-reached'))
      return
    }

    const isUserConnectingDifferentAccountError =
      isAxiosError(error) &&
      error.response?.status === 409 &&
      error.response.data?.codeDescription ===
        'ITEM_ORIGINAL_CONNECTED_WITH_DIFFERENT_ACCOUNT'

    if (isUserConnectingDifferentAccountError) {
      setErrorMessage(
        <Trans
          i18nKey={
            'connectorForm.error.item-updating-with-different-credentials'
          }
        />,
      )
      return
    }

    if (isValidationErrorResponse(error)) {
      // error is ValidationErrorResponse, update individual field error messages if applicable
      const validationErrorResponse = getErrorResponse(error)
      if (validationErrorResponse.details?.length) {
        const newFormErrors: FieldErrors = {}
        for (const { parameter, message } of validationErrorResponse.details) {
          newFormErrors[parameter] = message
        }
        if (Object.keys(newFormErrors).length > 0) {
          // has new form errors, set them
          setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            ...newFormErrors,
          }))
        }
      }
      return
    }

    if (mfaFormCredential && isPluggyServerError(error)) {
      // item is MFA 2-step -> error related to MFA parameter?
      const serverErrorResponse = getErrorResponse(error)

      console.log('Error related to MFA 2-step parameter result', {
        serverErrorResponse,
      })
      // is 2-step MFA parameter error
      const isExpiredMfa = serverErrorResponse.code === HttpStatusCode.NOT_FOUND
      const userErrorMessage = isExpiredMfa
        ? t('connectorForm.error.mfa_parameter_expired')
        : t('connectorForm.error.unknown_mfa_error')
      setErrorMessage(userErrorMessage)
      setInputValues({})
      return
    }
    // item non MFA 2-step
    // API server error or unknown error (code: 502, code: 500, Network error...)
    // Note: we report to Sentry to better understand what kind of errors could we be getting here.
    //  We know code: 502 is one of them but there might be others.
    addBreadcrumb({
      level: Severity.Error,
      data: { error },
    })

    if (isAxiosError(error)) {
      const { code } = error

      error.message = `ConnectForm: Unexpected or unknown error from pluggy-api (${code}): ${error.message}`
      captureException(error, {
        level: Severity.Critical,
        fingerprint: [error.message],
      })
    } else {
      error.message = `ConnectForm: Unexpected or unknown error: '${error.message}'`
      captureException(error, {
        level: Severity.Critical,
        fingerprint: [error.message],
      })
    }
    setErrorMessage(t('connectorForm.error.unknown'))
    setInputValues({})
  }, [
    onClearConnectItem,
    isCreateLoading,
    isUpdate,
    item,
    itemMfa1StepCredential,
    error,
    t,
    pollingStopped,
    isPollingItemAnyAction,
    previousWasPollingItemAnyAction,
    mfaFormCredential,
    selectedConnector,
    itemsCreateLimit,
    isQRConnectorFlow,
    isUserInputTimeout,
    isActiveItemWithSameCredentialsExecutingError,
  ])

  const setMfaRemainingTimeWithApiDate = useCallback(async () => {
    if (!mfaFormCredential) {
      return
    }

    const initialRemainingTime = await getMfaInitialRemainingTime(
      mfaFormCredential,
      serverDateDeltaInMs,
    )

    if (!initialRemainingTime) {
      // if initial time couldn't be fetched, don't start timer
      // so user at least can set mfa parameter
      return
    }

    // initialize MFA timer
    setRemainingTimeMs(initialRemainingTime)
  }, [serverDateDeltaInMs, mfaFormCredential])

  useEffect(() => {
    setMfaRemainingTimeWithApiDate()
  }, [setMfaRemainingTimeWithApiDate, mfaFormCredential, onRetry])

  const handleSimuleTimeoutClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      // reduce MFA timer to *almost* timeout
      if (
        remainingTimeMs === undefined ||
        remainingTimeMs < MFA_ALMOST_TIMEOUT_MS
      ) {
        return
      }
      setRemainingTimeMs(MFA_ALMOST_TIMEOUT_MS)
    },
    [remainingTimeMs],
  )

  const [currentAccountFlowOption, setCurrentAccountFlowOption] =
    useState<AccountFlowOption>()

  const [selectedAccountFlowOption, setSelectedAccountFlowOption] =
    useState<AccountFlowOption>()

  const isAccountFlowSelection =
    !isUpdate &&
    hasConnectorAccountFlowOptions(selectedConnector) &&
    !selectedAccountFlowOption

  const handleAccountFlowSelectionSubmit = useCallback(() => {
    setSelectedAccountFlowOption(currentAccountFlowOption)
    const { id: connectorId, name: connectorName } = selectedConnector
    track(TrackEventName.FORM_SUBMITTED, {
      location: 'connectForm',
      form: FORM_NAME.ACCOUNT_FLOW_SELECTION,
      connectorId,
      connectorName,
      value: currentAccountFlowOption,
    })
  }, [currentAccountFlowOption, selectedConnector])

  // we are on a custom flow where the user needs to select a coopeartive before send the credentials
  // this is for special connectors like Ailos
  const isCooperativeSelectionFlow =
    hasCooperativeSelectionFlow(selectedConnector) && !isUpdate

  const [currentCooperative, setCurrentCooperative] = useState<
    string | undefined
  >()

  const [selectedCooperative, setSelectedCooperative] = useState<
    string | undefined
  >()

  const isUserSelectingCooperative =
    isCooperativeSelectionFlow && !selectedCooperative

  const handleCooperativeFlowSelectionSubmit = useCallback(() => {
    setSelectedCooperative(currentCooperative)
    const { id: connectorId, name: connectorName } = selectedConnector
    track(TrackEventName.FORM_SUBMITTED, {
      location: 'connectForm',
      form: FORM_NAME.COOPERATIVE_FLOW_SELECTION,
      connectorId,
      connectorName,
      value: currentCooperative,
    })
  }, [currentCooperative, selectedConnector])

  const cooperativeFlowSelectionSelectField = isCooperativeSelectionFlow
    ? selectedConnector.credentials.filter(
        (field: ConnectorCredential) => field.type === 'select',
      )[0]
    : undefined

  // custom flow for connectors like Ailos, we set the selected cooperative
  // in the form (also if the inputValues are removed), so we handle it in a internal state
  // to avoid the user to select a cooperative more than once
  useEffect(() => {
    if (!selectedCooperative || !isCooperativeSelectionFlow) {
      // is not cooperative flow or user has not selected a cooperative
      return
    }

    const cooperativeSelectFieldName =
      cooperativeFlowSelectionSelectField?.name || ''

    const inputValuesKeys = Object.keys(inputValues)

    if (inputValuesKeys.includes(cooperativeSelectFieldName)) {
      // we have the cooperative selection field in the input values -> just return
      return
    }

    // user has selected a cooperative or the form has an error and the cooperative
    // was removed from the values and was already selected -> add it to the input values
    const newInputValues = {
      ...inputValues,
      [cooperativeSelectFieldName]: selectedCooperative,
    }

    setInputValues(newInputValues)
  }, [
    cooperativeFlowSelectionSelectField,
    inputValues,
    isCooperativeSelectionFlow,
    selectedCooperative,
  ])

  const formDefinitions: Record<
    | 'Mfa2Step'
    | 'Login'
    | 'AccountFlowSelect'
    | 'CooperativeFlowSelect'
    | 'QRScanFlow',
    ConnectFormProps
  > = {
    Login: {
      onSubmit: handleSubmit,
      className: 'login-form',
      fields: selectedConnector.credentials.map(
        (credential: ConnectorCredential): ConnectFormFieldProps => ({
          name: credential.name,
          type:
            credential.type === 'password'
              ? 'password'
              : credential.type === 'select'
              ? 'select'
              : credential.type === 'ethaddress'
              ? 'ethaddress'
              : credential.type === 'hcaptcha'
              ? 'hcaptcha'
              : 'text',
          options: credential.options,
          value: inputValues[credential.name] || '',
          isOptional: Boolean(credential.optional),
          label: credential.label,
          isMfa: Boolean(credential.mfa),
          validation: credential.validation,
          validationMessage: credential.validationMessage,
          instructions: credential.instructions,
          assistiveText: credential.assistiveText,
          placeholder: credential.placeholder,
        }),
      ),
    },
    Mfa2Step: {
      onSubmit: handleSubmitMfa,
      className: 'mfa-2step-login-form',
      fields: [
        {
          name: mfaFormCredential?.name || 'mfaToken',
          type:
            mfaFormCredential?.type === 'password'
              ? 'password'
              : mfaFormCredential?.type === 'select'
              ? 'select'
              : mfaFormCredential?.type === 'hcaptcha'
              ? 'hcaptcha'
              : 'text',
          options:
            mfaFormCredential?.type === 'select'
              ? mfaFormCredential.options
              : undefined,
          value: inputValues[mfaFormCredential?.name || 'mfaToken'] || '',
          label: mfaFormCredential?.label || '',
          validation: mfaFormCredential?.validation,
          validationMessage: mfaFormCredential?.validationMessage,
          instructions: mfaFormCredential?.instructions,
          assistiveText: mfaFormCredential?.assistiveText,
          placeholder: mfaFormCredential?.placeholder,
          imageData:
            (mfaFormCredential?.type === 'image' && item?.parameter?.data) ||
            undefined,
          isMfa: true,
          isOptional: false,
        },
      ],
    },
    AccountFlowSelect: {
      onSubmit: handleAccountFlowSelectionSubmit,
      className: 'account-flow-select',
      fields: [],
    },
    CooperativeFlowSelect: {
      onSubmit: handleCooperativeFlowSelectionSubmit,
      className: 'cooperative-flow-select',
      fields: [],
    },
    QRScanFlow: {
      onSubmit: handleQRflowSubmit,
      className: 'qr-scan-flow',
      fields: [],
    },
  }

  const isMfa2StepForm = mfaFormCredential !== undefined

  const currentForm = isMfa2StepForm
    ? formDefinitions.Mfa2Step
    : isAccountFlowSelection
    ? formDefinitions.AccountFlowSelect
    : isCooperativeSelectionFlow && !selectedCooperative
    ? formDefinitions.CooperativeFlowSelect
    : isQRConnectorFlow
    ? formDefinitions.QRScanFlow
    : formDefinitions.Login

  const connectorKey = [
    selectedConnector.id,
    selectedConnector.name.replace(/\s/g, ''),
  ].join('-')

  // flag to know when the user already submit the form at least once
  const userAlreadySubmitForm = useRef<boolean>(false)

  const handleConnectFormSubmit = useCallback(
    (
      event:
        | React.FormEvent<HTMLFormElement>
        | React.MouseEvent<HTMLButtonElement>,
    ): void => {
      event.preventDefault()
      if (isCreateLoading || isAnySubmitLoading) {
        return
      }

      if (isMfa2StepForm && isMfaRequestExpired && item) {
        // MFA parameter reset submit action
        setErrorMessage(undefined)
        setInputValues({})
        setFormErrors({})
        if (itemMfa1StepCredential) {
          // reset 1-step parameter form
          setMfaFormCredential({ ...itemMfa1StepCredential, stage: '1-step' })
          return
        }
        // trigger update request, to create a new MFA parameter request
        onUpdateItem(item.id)
        return
      }

      const fieldsErrors = validateFields(inputValues, currentForm.fields)
      if (Object.keys(fieldsErrors).length > 0) {
        setFormErrors(fieldsErrors)
        return
      }
      currentForm.onSubmit()
      userAlreadySubmitForm.current = true
    },
    [
      currentForm,
      inputValues,
      isAnySubmitLoading,
      isCreateLoading,
      isMfa2StepForm,
      isMfaRequestExpired,
      item,
      itemMfa1StepCredential,
      onUpdateItem,
    ],
  )

  // in this case, we are in a non-error item status, such as "WAITING_USER_INPUT", but we have an error in a field
  // that is not presented in the current form. So we need to set an Alert error insted
  useEffect(() => {
    const formErrorsKeys = Object.keys(formErrors)
    if (errorMessage || formErrorsKeys.length === 0) {
      // we already have the error message setted or there is no errors, just return
      return
    }
    // in this case, we are on 2-step form but we are receiving an error from another field
    // this could happen if the connector has multi-MFA parameters and the user
    // has an error in the token and we should restart all the MFA steps from the beginning
    if (
      mfaFormCredential?.stage === '2-step' &&
      formErrorsKeys.length === 1 &&
      currentForm.fields.length === 1 &&
      formErrorsKeys[0] !== currentForm.fields[0].name
    ) {
      // remove form error because it is from another field and it will not show up
      setFormErrors({})
      // remove values to prevent from blocking the user
      setInputValues({})
      setErrorMessage(t('connectorForm.error.invalid_credentials_multi_mfa'))
    }
  }, [currentForm, errorMessage, formErrors, mfaFormCredential, t])

  const mfa2StepParameterField = isMfa2StepForm
    ? currentForm.fields[0]
    : undefined

  const mfa1StepParameterField = currentForm.fields.find((field) => field.isMfa)

  useEffect(() => {
    // if 'joint' account flow, initialize MFA 1-step parameter mock value
    const HIDDEN_MFA_PARAMETER_VALUE = '000000'
    if (selectedAccountFlowOption !== 'joint' || !mfa1StepParameterField) {
      return
    }
    if (mfaFormCredential) {
      // is MFA form parameter state, nothing to do
      return
    }
    const { name } = mfa1StepParameterField
    const value = inputValues[name]
    if (value === HIDDEN_MFA_PARAMETER_VALUE) {
      // value is already set, don't set it again
      return
    }
    // set inputValues directly, to prevent side effects, and we don't need a re-render in this case
    inputValues[name] = HIDDEN_MFA_PARAMETER_VALUE
  }, [
    selectedAccountFlowOption,
    mfa1StepParameterField,
    handleInputChange,
    mfaFormCredential,
    inputValues,
  ])

  const shouldShowMfaRemainingTime =
    remainingTimeMs !== undefined &&
    (remainingTimeMs > 0 ||
      (remainingTimeMs <= 0 && !mfa2StepParameterField?.imageData))

  const isUpdateAndUserAlreadySubmittedFirstLogin =
    isUpdate && userAlreadySubmitForm.current

  // only disable form inputs when the item is in error state:
  // "ACCOUNT_NEEDS_ACTION",
  // "ACCOUNT_LOCKED",
  // "ACCOUNT_CREDENTIALS_RESET", or
  // "USER_NOT_SUPPORTED"
  // and user is not in update mode
  // or user is in update mode and at least we try to update the execution once
  // or there is an active item with same credentials
  // note: if it's 1-step MFA, it needs a manual submit to update the execution since we need the token
  const isDisableFormInputsExecutionError =
    ((item?.executionStatus === 'ACCOUNT_NEEDS_ACTION' ||
      item?.executionStatus === 'ACCOUNT_CREDENTIALS_RESET' ||
      item?.executionStatus === 'USER_NOT_SUPPORTED' ||
      item?.executionStatus === 'ACCOUNT_LOCKED') &&
      (!isUpdate || isUpdateAndUserAlreadySubmittedFirstLogin)) ||
    isActiveItemWithSameCredentialsExecutingError

  const previousFormErrors = usePrevious<FieldErrors>(formErrors)

  useEffect(() => {
    const formErrorsKeys = Object.keys(formErrors)
    const formErrorsValues = Object.values(formErrors)

    const hasNewErrors = formErrorsKeys.some((key: string) => {
      if (previousFormErrors === undefined) {
        return true
      }
      const formErrorValue = formErrors[key]
      return formErrorValue && formErrorValue !== previousFormErrors[key]
    })
    if (!hasNewErrors) {
      return
    }
    const {
      name: connectorName,
      id: connectorId,
      type: connectorType,
    } = selectedConnector
    track(TrackEventName.FORM_ERROR_RECEIVED, {
      errorFields: formErrorsKeys,
      errorMessages: formErrorsValues,
      connectorName,
      connectorId,
      connectorType,
    })
  }, [formErrors, previousFormErrors, selectedConnector])

  const isResendingMfaToEmail =
    mfaFormCredential?.stage === '2-step' &&
    isMfaRequestExpired &&
    (isItemUpdating || isPollingItemUpdate) // TODO only when this send is user-triggered

  const isMfaEmailFlow = hasEmailMfaFlow(selectedConnector)

  const isMoveSecurityFlow = moveSecurityData !== undefined
  const isConnectorMfa2Step =
    selectedConnector.hasMFA && !itemMfa1StepCredential

  // submit button text depends on the current flow we are in
  // (isUpdate mode, or special MFA flow, or other)
  let submitButtonI18nKey: string
  if (isUpdate) {
    // is in update mode
    if (isResendingMfaToEmail) {
      // we are in update mode and the user requests for a new mfa token
      submitButtonI18nKey = 'connectorForm.sending'
    } else if (isAnySubmitLoading) {
      // is updating credentials
      submitButtonI18nKey = 'connectorForm.updating'
    } else {
      // is on update mode, but not updating
      submitButtonI18nKey = 'connectorForm.submit-update'
    }
  } else if (isQRConnectorFlow) {
    // is not update mode but still updating an item -> we are retrying
    if (isUserInputTimeout || isPollingItemUpdate || isItemUpdating) {
      submitButtonI18nKey = 'connectorForm.retry'
    } else {
      submitButtonI18nKey = 'connectorForm.continue'
    }
  } else if (isMfaEmailFlow) {
    // mfa email flow. TODO: for now only nubank and cei but should affect ALL mfa 2-step
    if (isResendingMfaToEmail) {
      // the user requests for a new mfa token
      submitButtonI18nKey = 'connectorForm.sending'
    } else if (isAnySubmitLoading) {
      // is submiting credentials
      submitButtonI18nKey = 'connectorForm.connecting'
    } else {
      submitButtonI18nKey = 'connectorForm.continue'
    }
  } else {
    // is not in update mode
    if (isAnySubmitLoading) {
      // submitting
      submitButtonI18nKey = 'connectorForm.connecting'
    } else if (isUserSelectingCooperative) {
      // custom cooperative selection flow
      submitButtonI18nKey = 'connectorForm.continue'
    } else if (isMoveSecurityFlow) {
      // is move security previdencia flow
      if (isConnectorMfa2Step && !isMfa2StepForm) {
        // is first login step, mfa 2-step is still pending
        submitButtonI18nKey = 'connectorForm.continue'
      } else {
        // is final login, display message for move previdencia request
        submitButtonI18nKey = 'connectorForm.move-security-submit'
      }
    } else {
      submitButtonI18nKey = 'connectorForm.submit'
    }
  }

  const { titleI18nKey } = useConnectFormHeaderI18nKey(
    item,
    mfaFormCredential,
    selectedConnector,
    isUpdate,
  )

  const submitButtonText = getConnectButtonText(
    submitButtonI18nKey,
    customFormButtonText,
  )

  const handleConnectFormButtonClick = useCallback(() => {
    track(TrackEventName.BUTTON_CLICKED, {
      text: submitButtonText,
      i18nKey: submitButtonI18nKey,
      location: 'connectorForm',
    })
  }, [submitButtonI18nKey, submitButtonText])
  const isMfaOptionSelection = currentForm.fields.some(
    (field) => field.type === 'select',
  )

  const inputValidateHandler = useCallback(
    (connectFormField: ConnectFormFieldProps) => {
      return function validateInput() {
        const { value, name } = connectFormField
        try {
          validateField(connectFormField, value)
        } catch (error_) {
          // validation error, update form errors state
          setFormErrors((prevFormErrors) => ({
            ...prevFormErrors,
            [name]: error_.message,
          }))
        }
      }
    },
    [],
  )

  // track inputs focus state to hide/show masked input pattern placeholder
  const [currentInputsFocused, setCurrentInputsFocused] = useState<
    Record<string, boolean | undefined>
  >({})

  const updateInputFocusHandler = useCallback(
    (connectFormField: ConnectFormFieldProps, focusedValue: boolean) => {
      return function updateInputFocus_() {
        return setCurrentInputsFocused((previousInputFocus) => ({
          ...previousInputFocus,
          [connectFormField.name]: focusedValue,
        }))
      }
    },
    [],
  )

  const helpLinks = getHelpLinksByConnector(selectedConnector)

  const isWaitingUserActionExecutionStatus =
    item?.executionStatus === 'WAITING_USER_ACTION'

  const isOauthUserInputTimeout = selectedConnector.oauth && isUserInputTimeout

  const currentFormFields = isWaitingUserActionExecutionStatus
    ? [] // remove fields on waiting user action flows
    : isCooperativeSelectionFlow && !isMfa2StepForm
    ? // is Ailos initial Cooperative selection - remove the cooperative selection fields from the current form fields
      currentForm.fields.filter((field) => field.type !== 'select')
    : currentForm.fields

  const cooperativeOptions = cooperativeFlowSelectionSelectField?.options

  const isLoading = isCreateLoading || isAnySubmitLoading

  // login credentials form
  const isCredentialsForm = currentForm.className === 'login-form'

  const isItemsCreateQuotaExceeded =
    isItemsCreateQuotaExceededProp && !isSandbox

  const isButtonDisabled =
    Boolean(isMfaRequestExpired) ||
    isAnySubmitLoading ||
    isDisableFormInputsExecutionError ||
    (isAccountFlowSelection && !currentAccountFlowOption) ||
    (isMfa2StepForm &&
      mfaFormCredential.stage === '2-step' &&
      !inputValues[mfaFormCredential.name]) ||
    (isUserSelectingCooperative && !currentCooperative) ||
    isItemsCreateQuotaExceeded

  const isTitleVisible = !isMfaOptionSelection || !isMfaRequestExpired

  const showGenialMfaInstructions =
    isMfa2StepForm && isGenialConnector(selectedConnector)

  const itemWasConnectedButHasInvalidCredentials =
    isUpdate &&
    item?.lastUpdatedAt !== null &&
    item?.executionStatus === 'INVALID_CREDENTIALS' &&
    !isQRConnectorFlow // It doesn't make sense to show this message for QR connectors

  const displayLoadingOverlay =
    (isCreateLoading || isAnySubmitLoading) &&
    // don't display loading overlay if item is on WAITING_USER_ACTION
    // as this is an external flow which we can't follow
    item?.executionStatus !== 'WAITING_USER_ACTION' &&
    // if item is waiting oauth MFA, don't display loading overlay
    // since we can't reliably track the state of that flow;
    // we just continue polling the item silently
    (item ? !getItemParameterOauthUrl(item) : true)

  const handleSandboxCredentialSuggestionSelect = useCallback(
    (field: FieldValues) => {
      const [fieldName] = Object.keys(field)
      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        ...field,
      }))

      setFormErrors((previousFormErrors_) => {
        // disabling this rule because we want to remove the key from the object
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [fieldName]: _removedKey, ...restFormErrors } =
          previousFormErrors_

        return restFormErrors
      })
    },
    [],
  )

  const [isShowingOpenFinancePermissions, setIsShowingOpenFinancePermissions] =
    useState<boolean>(false)

  const showOpenFinancePermissions = useCallback(() => {
    setIsShowingOpenFinancePermissions(true)
  }, [])

  const handleOpenFinancePermissionsClose = useCallback(() => {
    setIsShowingOpenFinancePermissions(false)
  }, [])

  return (
    <div className="ConnectForm">
      {displayLoadingOverlay && <div className={'loading-overlay'} />}
      {isTitleVisible && <Title>{t(titleI18nKey)}</Title>}
      {isShowingOpenFinancePermissions && (
        <OpenFinancePermissions onClose={handleOpenFinancePermissionsClose} />
      )}
      {isOauthForm || isOauthMfaParameter ? (
        <OauthConnectForm
          item={item}
          onShowOpenFinancePermissions={showOpenFinancePermissions}
        />
      ) : isMfaOptionSelection && isMfaRequestExpired ? (
        <MfaSelectRequestExpired onRetry={onRetry} />
      ) : (
        <Form
          className={`${currentForm.className} ${connectorKey} ${
            mfa2StepParameterField?.imageData ? 'has-mfa-image' : ''
          }`}
          onSubmit={handleConnectFormSubmit}
          noValidate
          error={!!errorMessage}
        >
          <ConnectorHeader
            connector={selectedConnector}
            withImage={withImage}
          />
          {isOauthUserInputTimeout && (
            <OauthTimeoutError onTryAgain={onRetry} />
          )}
          <div
            className={`form-fields ${
              isOauthUserInputTimeout ? 'oauth-timeout' : ''
            }`}
          >
            {item?.executionStatus === 'WAITING_USER_INPUT' &&
              item.lastUpdatedAt !== null &&
              isUpdate && (
                <Alert
                  size={'small'}
                  message={t('connectorForm.update.mfa-needed-info-message')}
                />
              )}
            {isItemsCreateQuotaExceeded ? (
              <Alert
                type="error"
                message={
                  <Trans
                    i18nKey={'connectorForm.error.item-creation-limit-reached'}
                    values={{ limit: itemsCreateLimit }}
                  />
                }
                size="medium"
              />
            ) : errorMessage ? (
              <>
                <Alert type={'error'} message={errorMessage} size="medium" />
                {item?.error?.providerMessage && (
                  <Alert
                    type={'info'}
                    message={
                      <>
                        {t('connectorForm.error.provider_message_prefix')}
                        <br />
                        {item.error.providerMessage}
                      </>
                    }
                    size={'small'}
                  />
                )}
              </>
            ) : (
              !isCreateLoading &&
              !isAnySubmitLoading &&
              selectedConnector.health.status === 'UNSTABLE' && (
                <Alert
                  type={'warning'}
                  message={t('connectorForm.message.unstable')}
                />
              )
            )}

            {!errorMessage && itemWasConnectedButHasInvalidCredentials && (
              <Alert
                type={'info'}
                message={t(
                  'connectorForm.info.update-item-needs-new-credentials',
                )}
              />
            )}
            {!isDisableFormInputsExecutionError &&
              item &&
              item.consecutiveFailedLoginAttempts >= 2 && (
                <Alert
                  type={'warning'}
                  message={
                    <Trans
                      i18nKey={'connectorForm.consecutive-login-errors.warning'}
                      values={{
                        attempts: item.consecutiveFailedLoginAttempts,
                      }}
                    />
                  }
                />
              )}

            {hasDeviceRegistrationNotificationAlert(selectedConnector) &&
              !errorMessage &&
              !isItemsCreateQuotaExceeded && (
                <Alert
                  className={'device-registration-notification-alert'}
                  message={t(
                    'connectorForm.message.device-registration-notification-alert',
                  )}
                />
              )}

            {isPJOpenFinance(selectedConnector) && (
              <Alert
                type="info"
                message={
                  <>
                    Atenção: recomendamos o uso de um perfil
                    &quot;Administrador&quot; para realizar a conexão; perfis
                    &quot;Operador&quot; podem não ser aceitos
                  </>
                }
              />
            )}
            {isINSSConnector(selectedConnector) &&
              !errorMessage &&
              !isItemsCreateQuotaExceeded && (
                <Alert
                  className={'use-gov-br-credentials-notification-alert'}
                  message={t(
                    'connectorForm.message.use-gov-br-credentials-notification-alert',
                  )}
                />
              )}
            {isUserSelectingCooperative && (
              <p className={'cooperative-selection-message'}>
                {t('connectorForm.message.cooperative-selection')}
              </p>
            )}
            {mfa2StepParameterField &&
              !isPersonalSafraConnector(selectedConnector) && (
                <p className={'mfa-message'}>
                  <Mfa2StepInstructions
                    hasEmailMfaFlow={isMfaEmailFlow}
                    message={
                      mfa2StepParameterField.instructions ||
                      t('connectorForm.mfa.message')
                    }
                    isDisabled={isMfaRequestExpired}
                  />
                </p>
              )}
            {(mfa2StepParameterField?.imageData || isQRLoginFlowShowingQR) && (
              <div
                className={`mfa-image-container ${
                  isQRLoginFlowShowingQR ? 'qr-login-container' : ''
                }`}
              >
                {(remainingTimeMs === undefined || remainingTimeMs > 0) && (
                  <Image
                    className={'field-image'}
                    centered
                    src={
                      isQRLoginFlowShowingQR
                        ? item.userAction?.attributes?.data
                        : mfa2StepParameterField?.imageData
                    }
                  />
                )}
                {remainingTimeMs !== undefined && (
                  <MfaRemainingTimeMessage
                    remainingTime={remainingTimeMs}
                    isSandbox={isSandbox}
                    isLoading={isAnySubmitLoading}
                    shouldShowMfaRemainingTime={shouldShowMfaRemainingTime}
                    onSimuleTimeoutClick={handleSimuleTimeoutClick}
                    onTryAgainSubmit={handleConnectFormSubmit}
                  />
                )}
              </div>
            )}
            {isAccountFlowSelection && (
              <AccountFlowSelection
                selected={currentAccountFlowOption}
                onSelect={setCurrentAccountFlowOption}
                disabled={isItemsCreateQuotaExceeded}
              />
            )}
            {isUserSelectingCooperative && (
              <CooperativeFlowSelection
                onSelect={setCurrentCooperative}
                cooperativeOptions={cooperativeOptions}
                selected={currentCooperative}
                disabled={isItemsCreateQuotaExceeded}
              />
            )}
            {isQRConnectorFlow && isUserInputTimeout ? (
              <QRActionTimeout />
            ) : (
              !isQRLoginFlowShowingQR &&
              isQRConnectorFlow && <QRActionInstructions />
            )}
            {isWaitingUserActionExecutionStatus && (
              <UserActionInstructions item={item} />
            )}
            {isWiseConnector(selectedConnector) && <WiseInstructions />}

            {currentFormFields.map((currentField, index) => {
              const {
                assistiveText,
                isOptional,
                name,
                label,
                options,
                type,
                value,
                placeholder,
                isMfa,
                validation,
                instructions,
              } = currentField

              const key = `${currentForm.className}_${index}_${name}_${label}_${assistiveText}`

              const disabled = Boolean(
                isCreateLoading ||
                  isAnySubmitLoading ||
                  isMfaRequestExpired ||
                  isDisableFormInputsExecutionError ||
                  isItemsCreateQuotaExceeded,
              )

              // check input error state
              const fieldError = formErrors[name]
              const isItemInvalidCredentialsError =
                errorMessage && item?.status === 'LOGIN_ERROR'

              const inputError: string | boolean | undefined =
                !disabled && (fieldError || isItemInvalidCredentialsError)

              // if it's a 'joint' account flow (ie. 'Bradesco conta conjunta')
              // hide the MFA 1-step credential, it will be asked later as a separated step
              const isHiddenMfa1StepParameter =
                isMfa && !isMfa2StepForm && currentAccountFlowOption === 'joint'

              const classNames = []
              if (isHiddenMfa1StepParameter) {
                classNames.push('hidden')
              }
              const className = classNames.join(' ')
              const isFocused = Boolean(currentInputsFocused[index])

              const isFileUploadInput_ = isFileUploadInput(currentField)

              const firstFileUploadInputIndex =
                currentFormFields.findIndex(isFileUploadInput)
              const isFirstFileUploadInput = index === firstFileUploadInputIndex

              if (type === 'text' || type === 'password') {
                const inputWithMask = MaskedInput({
                  validation,
                  isFocused,
                  onAccept: (value_, _mask) => {
                    handleInputChange({ name, value: String(value_) })
                  },
                  isPassword: type === 'password',
                })

                if (isFileUploadInput_) {
                  return (
                    <>
                      {/* Only Show "Upload Files" header above first File Input*/}
                      {isFirstFileUploadInput && (
                        <Header className="file-upload-header">
                          {t('connectorForm.file-upload-header')}
                        </Header>
                      )}
                      <Input
                        type={'file'}
                        key={key}
                        className={className}
                        name={name}
                        disabled={disabled}
                        error={inputError}
                        assistiveText={assistiveText}
                        optionalMessage={
                          isOptional
                            ? t('connectorForm.optional-field-message')
                            : undefined
                        }
                        onChange={inputWithMask ? undefined : handleInputChange}
                        fileRequestedLabel={label}
                        replaceLabel={t('connectorForm.replace-file-label')}
                        retryLabel={t('connectorForm.retry-file-upload-label')}
                        uploadLabel={t('connectorForm.file-upload-label')}
                        onFocus={updateInputFocusHandler(currentField, true)}
                        onBlur={() => {
                          updateInputFocusHandler(currentField, false)()
                          inputValidateHandler(currentField)()
                        }}
                        acceptedFileFormats={
                          instructions
                            ? extractFileExtensions(instructions)
                            : undefined
                        }
                      />
                    </>
                  )
                }

                return (
                  <div key={key} className={'input-container'}>
                    <Input
                      className={className}
                      name={name}
                      type={type}
                      disabled={disabled}
                      error={inputError}
                      assistiveText={assistiveText}
                      placeholder={placeholder}
                      optionalMessage={
                        isOptional
                          ? t('connectorForm.optional-field-message')
                          : undefined
                      }
                      input={inputWithMask || <input {...BASE_INPUT_MODE} />}
                      label={label}
                      value={value}
                      onChange={inputWithMask ? undefined : handleInputChange}
                      onFocus={updateInputFocusHandler(currentField, true)}
                      onBlur={() => {
                        updateInputFocusHandler(currentField, false)()
                        inputValidateHandler(currentField)()
                      }}
                    />

                    {isSandboxConnector(selectedConnector) &&
                      !selectedConnector.isOpenFinance &&
                      !isAnySubmitLoading && (
                        <SandboxCredentialSuggestion
                          selectedConnector={selectedConnector}
                          inputName={name}
                          inputValue={value}
                          inputType={type}
                          onSandboxCredentialSuggestionSelect={
                            handleSandboxCredentialSuggestionSelect
                          }
                          isInputFocused={Boolean(currentInputsFocused[name])}
                        />
                      )}
                  </div>
                )
              }

              if (type === 'ethaddress') {
                return (
                  <ConnectWalletInput
                    key={key}
                    value={value}
                    error={typeof inputError === 'string' ? inputError : null}
                    onConnectStart={() => {
                      // clear form value & error
                      setInputValues({})
                      resetFormError(name)
                    }}
                    onConnectSuccess={(account: string) =>
                      handleInputChange({ name, value: account })
                    }
                    onError={(error_: string) => {
                      // input resulted in error, update formErrors state
                      setFormErrors((prevFormErrors) => ({
                        ...prevFormErrors,
                        [name]: error_,
                      }))
                    }}
                  />
                )
              }

              if (type === 'hcaptcha') {
                return (
                  <div key={key} className="hcaptcha-iframe-container">
                    <HCaptcha
                      sitekey="93b08d40-d46c-400a-ba07-6f91cda815b9"
                      sentry={false}
                      size="compact"
                      onVerify={(token) => {
                        handleInputChange({ name: 'hcaptcha', value: token })
                      }}
                      languageOverride="pt-BR"
                    />
                  </div>
                )
              }
              // type === 'radio-select', pass options to Input as well.
              return (
                <Input
                  type={'radio-select'}
                  key={key}
                  options={options || []}
                  onChange={handleRadioSelectInputChange}
                  value={value}
                  label={label}
                  name={name}
                  className={className}
                  disabled={disabled}
                  error={inputError}
                  assistiveText={assistiveText}
                  optionalMessage={
                    isOptional
                      ? t('connectorForm.optional-field-message')
                      : undefined
                  }
                />
              )
            })}
            {helpLinks && !item && (
              <>
                {helpLinks.length > 1 && (
                  <div>{t('connectorForm.help-link.links.title')}</div>
                )}
                {helpLinks.map(({ i18nKey, link }) => (
                  <a
                    key={i18nKey}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link help-link"
                    onClick={() => {
                      track(TrackEventName.LINK_CLICKED, {
                        i18nKey,
                        linkTo: link,
                      })
                    }}
                    href={link}
                  >
                    {t(i18nKey)}
                  </a>
                ))}
              </>
            )}
            {mfa2StepParameterField &&
              !mfa2StepParameterField.imageData &&
              remainingTimeMs !== undefined &&
              !isMfaOptionSelection &&
              !isWaitingUserActionExecutionStatus && (
                <MfaRemainingTimeMessage
                  remainingTime={remainingTimeMs}
                  isSandbox={isSandbox}
                  isLoading={isAnySubmitLoading}
                  shouldShowMfaRemainingTime={shouldShowMfaRemainingTime}
                  onSimuleTimeoutClick={handleSimuleTimeoutClick}
                  onTryAgainSubmit={handleConnectFormSubmit}
                />
              )}
            {showGenialMfaInstructions && <GenialMfaInstructions />}
          </div>

          <div className={'form-footer'}>
            {/* adding loading overlay in form footer to cover the reset password link */}
            {displayLoadingOverlay && <div className={'loading-overlay'} />}

            {isCredentialsForm &&
              !isWaitingUserActionExecutionStatus &&
              (selectedConnector.isOpenFinance ? (
                <OpenFinanceFAQButton onClick={onGoToFAQPage} />
              ) : (
                <ConnectionSecurePopup connector={selectedConnector} />
              ))}
            {!isQRLoginFlowShowingQR && !isOauthUserInputTimeout && (
              <Button
                primary
                type="submit"
                onClick={handleConnectFormButtonClick}
                loading={isLoading}
                disabled={isButtonDisabled}
              >
                {submitButtonText}
              </Button>
            )}
            {/* Reset password button - only show when requesting credentials */}
            {isCredentialsForm &&
              !isSandbox &&
              !selectedConnector.isOpenFinance && (
                <div className={'action-link-container'}>
                  <a
                    onClick={trackResetPasswordLinkClick}
                    href={selectedConnector.institutionUrl}
                    className={`link ${
                      isDisableFormInputsExecutionError ? 'disabled' : ''
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {resetPasswordLinkText}
                  </a>
                </div>
              )}
          </div>
        </Form>
      )}
    </div>
  )
}

export default React.memo(ConnectForm)
